import * as Sentry from '@sentry/astro';
import React, {FormEvent, useEffect} from 'react';
import {useState} from 'react';
import {AdminBackendConnection} from '../api/AdminBackendConnection';

export default function Login({BASE_URL}) {
    const userApi = new AdminBackendConnection(BASE_URL);

    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setIsLoading] = useState(false);

    const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            // Call the login API
            const response = await userApi.loginUser(emailAddress, password);

            if (response.accessToken) {
                console.log('Sentry setting email: ', emailAddress);
                Sentry.setUser({email: emailAddress, id: response.id});
                // Store token immediately (localStorage or sessionStorage)
                localStorage.setItem('accessToken', response.accessToken);

                const {userRights, userType, id, overviewAccess} = response;
                const userRightsKey = Object.keys(userRights)[0];

                // If user has ROOT_RIGHTS, skip fetching landing page and redirect to SuperAdmin dashboard
                if (userRightsKey === 'ROOT_RIGHTS') {
                    setErrorMessage('');
                    setIsLoading(false);
                    setSuccessMessage('Redirecting to SuperAdmin dashboard...');
                    window.location.href = '/SuperAdmin/dashboard';
                    return; // Exit the function after redirecting
                }

                // Fetch the landing page data for non-root users
                const landingPage = await userApi.getLandingPage();

                // Check if landingPage is null, handle it with a fallback or error
                if (!landingPage) {
                    throw new Error(
                        'Unable to load landing page. Please contact support.',
                    );
                }

                // Cache initialization (wait until the cache is set before redirecting)
                await userApi.initializeCacheOnLogin();

                const getPath = () => {
                    if (userType === 'Vendor_User') return '/vendor/dashboard/';
                    return overviewAccess
                        ? `/admin/${landingPage.type}/${response.id}`
                        : '/admin/messages/';
                };

                const path = getPath();
                const redirectUrl = `${path}${landingPage.id}`;

                // Reset error message, stop loading, and set success message
                setErrorMessage('');
                setIsLoading(false);
                setSuccessMessage('Redirecting to dashboard...');

                // Redirect the user after login success
                window.location.href = redirectUrl;
            } else {
                throw new Error(
                    'An error occurred while signing in. Please try again later.',
                );
            }
        } catch (error: any) {
            // Handle error cases
            setIsLoading(false);
            setSuccessMessage('');
            setErrorMessage(error.message || 'An error occurred');
        }
    };

    const checkUserLoggedInStatus = async () => {
        const token = localStorage.getItem('accessToken'); // Retrieve token from localStorage or sessionStorage

        if (!token) {
            // No token means the user is not logged in, so no need to make the API call
            console.log('No token found, user is not logged in.');
            return;
        }

        try {
            // If token exists, set it in the headers and check if the user is logged in
            const tokenValidity = await userApi.checkUserActive(); // Call to check if token is valid

            if (tokenValidity) {
                window.location.href = '/dashboard'; // If valid, redirect user to the dashboard
            }
        } catch (error) {
            // Handle the case where the token is invalid (401 Unauthorized)
            console.log('Error fetching data:', error.status);
        }
    };

    useEffect(() => {
        checkUserLoggedInStatus();
    }, []);

    return (
        <div className="max-w-md w-full space-y-8">
            <div className="bg-white rounded-lg shadow-lg p-6 border border-gray-300">
                <h3 className="text-2xl font-semibold text-gray-700">
                    WelcomeBob Admin Login
                </h3>
                <hr />

                {successMessage && (
                    <div
                        className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                        role="alert"
                    >
                        <strong className="font-bold">{successMessage}</strong>
                    </div>
                )}
                {errorMessage && (
                    <div
                        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                        role="alert"
                    >
                        <strong className="font-bold">{errorMessage}</strong>
                    </div>
                )}
                {loading && (
                    <div
                        className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative"
                        role="alert"
                    >
                        <strong className="font-bold">Please wait...</strong>
                    </div>
                )}

                <form onSubmit={handleLogin} className="mt-8 space-y-6">
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Email address
                        </label>
                        <div className="mt-1">
                            <input
                                type="email"
                                id="emailAddress"
                                autoFocus
                                autoComplete="username"
                                required
                                className="w-full px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                                value={emailAddress}
                                onChange={event =>
                                    setEmailAddress(event.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Password
                        </label>
                        <div className="mt-1">
                            <input
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                required
                                autoFocus
                                className="w-full px-4 py-2 transition duration-300 border border-gray-300 rounded focus:border-transparent focus:outline-none focus:ring-4 focus:ring-blue-200"
                                value={password}
                                onChange={event =>
                                    setPassword(event.target.value)
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium text-white bg-black border-gray-300 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Log in
                        </button>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="text-sm">
                            <a
                                href="/forgotpassword"
                                className="font-medium text-gray-600 hover:text-gray-300"
                            >
                                Forgot your password?
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
